var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 mb-4" }, [
          _c("h1", [
            _vm._v(
              "\n      What‘s your company name in " +
                _vm._s(_vm.jurisdiction.state_province_region) +
                "?\n    "
            ),
          ]),
        ]),
        _c("div", { staticClass: "col-md-12 mb-2 full-name-div" }, [
          _c("h5", { staticClass: "full-name-text" }, [
            _vm._v("\n      " + _vm._s(_vm.newFullCompanyName) + "\n    "),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "form-container col-12 col-md-9 d-flex flex-nowrap justify-content-center align-items-center m-auto",
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex flex-row justify-content-center",
                class: _vm.showEntityTypeSuffixes ? "col-md-6" : "col-md-6",
                attrs: { id: "company-name" },
              },
              [
                _c("b-form-input", {
                  attrs: { placeholder: "Company Name" },
                  model: {
                    value: _vm.newCompanyName,
                    callback: function ($$v) {
                      _vm.newCompanyName = $$v
                    },
                    expression: "newCompanyName",
                  },
                }),
              ],
              1
            ),
            _vm.showEntityTypeSuffixes
              ? _c(
                  "div",
                  { staticClass: "col-md-6", attrs: { id: "entity-suffix" } },
                  [
                    _c("b-form-select", {
                      staticClass: "w-100 pre-stageline-dropdown float-left",
                      attrs: { options: _vm.entityTypeSuffixes },
                      model: {
                        value: _vm.selectedEntitySuffix,
                        callback: function ($$v) {
                          _vm.selectedEntitySuffix = $$v
                        },
                        expression: "selectedEntitySuffix",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { staticClass: "button-nav-container col-12 col-md-9 m-auto" },
          [
            _c("previous-button-component"),
            _c(
              "button",
              {
                staticClass: "continue-btn btn btn-primary",
                attrs: {
                  "aria-label": "continue button",
                  disabled: !_vm.valid,
                },
                on: { click: _vm.checkForSimilarName },
              },
              [_vm._v("\n      Continue\n    ")]
            ),
          ],
          1
        ),
      ])
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }